<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label="标题名称:">
                        <el-input v-model='filters.name' placeholder="输入标题名称" clearable @clear='clearContent'></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="信息类型:">
                        <el-select v-model="filters.informationType" clearable>
                            <el-option
                            v-for="item in optionsData"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label="是否置顶:">
                        <el-select v-model="filters.isSetTop" clearable>
                            <el-option
                            v-for="item in optionsData2"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否启用:">
                        <el-select v-model="filters.isEnable" clearable>
                            <el-option
                            v-for="item in optionsData3"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>             
            </el-col>
        </el-row>
        <!--列表-->
        <el-table
            :data="listData"
            highlight-current-row
            v-loading="listLoading"
            border=""
            @current-change="selectCurrentRow"
            style="width: 100%;"
        >
            <el-table-column type="index" width="60" align="center" label="序号"></el-table-column>
            <el-table-column prop="Title" align="center" label="标题" width show-overflow-tooltip></el-table-column>
            <!-- <el-table-column prop="Content" align="center" label="内容" width show-overflow-tooltip></el-table-column> -->
            <el-table-column prop="InformationType" align="center" label="信息类型" width="100">
                <template slot-scope='scope'>
                    <el-tag v-if='scope.row.InformationType == 0' type='success'>法规要求</el-tag>
                    <el-tag v-if='scope.row.InformationType == 1'>公告信息</el-tag>
                    <el-tag v-if='scope.row.InformationType == 2' type='warning'>考试项目</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="IsSetTop" align="center" label="是否置顶" width="100">
                <template slot-scope='scope'>
                    <el-tag v-if='scope.row.IsSetTop == true' type='success'>是</el-tag>
                    <el-tag v-else type='warning'>否</el-tag>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="SetTopTime" align="center" label="置顶时间" width="260">
                <template slot-scope='scope'>
                    {{dateFormat('YYYY-mm-dd HH:MM:SS',scope.row.SetTopTime)}}
                </template>
            </el-table-column> -->
            <!-- <el-table-column prop="IsHtml" align="center" label="是否html" width>
                <template slot-scope='scope'>
                    <el-tag v-if='scope.row.IsHtml == true' type='success'>是</el-tag>
                    <el-tag v-else type='warning'>否</el-tag>
                </template>
            </el-table-column> -->
            <el-table-column prop='IsEnable' align='center' label='启用标识' width="100">
                <template slot-scope='scope'>                 
                    <el-tag v-if='scope.row.IsEnable == true' type='success'>启用</el-tag>
                    <el-tag v-else type='primary'>禁用</el-tag>
                </template>              
            </el-table-column>
            <el-table-column prop='Remarks' align='center' label='备注' width="200" show-overflow-tooltip></el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                align="center"
                width="200">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="primary" plain size="mini" icon="el-icon-notebook-1">查看</el-button>
                    <el-button type="danger" size="mini" @click='handleSetTop(scope.row.ID)' v-if='!scope.row.IsSetTop'>置顶</el-button>
                    <el-button type="primary" size="mini" @click='handleSetTop(scope.row.ID)' v-else>取消</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount">
            </el-pagination>
        </el-col>
        <!--新增/编辑界面-->
        <el-dialog
        :title="addOrEdi?'新增':'编辑'"
        :visible.sync="addFormVisible"
        v-model="addFormVisible"
        :close-on-click-modal="false"
        @close='handleClose'
        width="60%"
        >
            <el-form :model="formData" label-width="150px" :rules="formDataRules" ref="formData">
                <el-form-item label="标题：" prop="title">
                    <el-input v-model="formData.title" placeholder="请输入标题"></el-input>
                </el-form-item>
                <!-- <el-form-item label="信息类型：" prop="informationType">
                    <el-select v-model="formData.informationType">
                        <el-option
                        v-for="item in optionsData"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="是否链接：">
                    <el-switch v-model="formData.isHtml" active-color="#13ce66" inactive-color="#c9c9c9"></el-switch>
                </el-form-item>
                <el-form-item label="内容：" prop="content" v-if="!formData.isHtml">
                    <editor id="editor_id" height="400px" width="99%"
                        :content.sync="editorText"
                        :afterChange="afterChange()"
                        :uploadJson="uploadTempPic"
                        :pasteType="1"
                        :extraFileUploadParams="{token: token}"
                        :loadStyleMode="false"
                        @on-content-change="onContentChange"
                    >
                    </editor>
                </el-form-item>
                <el-form-item label="内容：" prop="netContent" v-if="formData.isHtml">
                    <el-input v-model="formData.netContent" placeholder="请输入http://xxx或https://xxx链接"></el-input>
                </el-form-item>
                <el-form-item label='备注：'>
                    <el-input v-model='formData.remarks' placeholder="请输入备注" type="textarea" :rows="2"></el-input>
                </el-form-item>
                <el-form-item label="封面照片：">
                    <el-upload
                    :action="uploadImagesUrl"
                    :headers="headers"
                    ref="upload"
                    :before-upload="beforeUpload"
                    :on-preview="handlePictureCardPreview"
                    :on-success="handle_success1"
                    :show-file-list="false"
                    list-type="picture-card"
                    :on-remove="handleRemove1"
                    >
                        <img v-if="formData.SignetWebImgUrl1" :src="formData.SignetWebImgUrl1" class="avatar" />
                        <i v-else class="el-icon-plus"></i>
                    </el-upload>
                    <div class="imgSpan2">只能上传jpg/png格式图片</div>
                </el-form-item>
<!--                <el-form-item label="附件图片：">-->
<!--                    <el-upload-->
<!--                        :action="uploadImagesUrl"-->
<!--                        :headers="headers"-->
<!--                        :before-upload="beforeUpload"-->
<!--                        :on-success="handle_success"-->
<!--                        list-type="picture-card"-->
<!--                        :on-preview="handlePictureCardPreview"-->
<!--                        :file-list="filesList"-->
<!--                        :on-remove="handleRemove">-->
<!--                            <i class="el-icon-plus"></i>-->
<!--                    </el-upload>-->
<!--                    <div class="imgSpan2">只能上传jpg/png格式图片</div>-->
<!--                </el-form-item>-->
                <el-form-item label='是否启用：'>
                    <el-select v-model="formData.isEnable" placeholder="请选择">
                        <el-option
                        v-for="item in optionsData4"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisible = false">取消</el-button>
                <el-button type="primary" @click.native="addSubmit" :loading="addLoading">发布</el-button>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
        <!--查看-->
        <el-dialog
        title="查看"
        :visible.sync="addFormVisible1"
        v-model="addFormVisible1"
        :close-on-click-modal="true"
        >
            <!-- <div>
                <div class="viwesTitle">{{viewsData.viwesTitle}}</div>
                <p class="viwesContent">{{viewsData.viwesContent}}</p>
            </div> -->
            <el-form label-width="150px">
                <el-form-item label="标题：">
                    <div>{{viewsData.viwesTitle}}</div>
                </el-form-item>
                <el-form-item label="信息类型：">
                    <div v-if="viewsData.viwesInformationType == 0">法规要求</div>
                    <div v-if="viewsData.viwesInformationType == 1">公告信息</div>
                    <div v-if="viewsData.viwesInformationType == 2">考试项目</div>
                </el-form-item>
                <el-form-item label="是否链接：">
                    <div>{{viewsData.viwesIsHtml?'是':'否'}}</div>
                </el-form-item>
                <!-- <el-form-item label="内容：">
                    <div>{{viewsData.viwesContent}}</div>
                </el-form-item> -->
                <el-form-item label="内容：">
                    <editor id="editor_id" height="400px" width="99%"
                        :content.sync="viewsData.viwesContent"
                        :loadStyleMode="false"
                        disabled="true"
                    >
                    </editor>
                </el-form-item>
                <el-form-item label="备注：">
                    <div>{{viewsData.viwesRemarks}}</div>
                </el-form-item>
                <el-form-item label="封面照片：">
                    <div class="pictureViews">
                        <img :src="viewsData.viwesCoverPhotoWebUrl" alt="">
                    </div>
                </el-form-item>
                <el-form-item label="附件图片：">
                    <div style="display:flex">
                        <div class="pictureViews" v-for="(item,index) in viewsData.viwesFilePhotoWebUrl" :key="index">
                            <img :src="item.AnnexPhotoWebUrl" alt="">
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="启用标识：">
                    <div>{{viewsData.viwesIsEnable?'启用':'禁用'}}</div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisible1 = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Toolbar from "../../components/Toolbar"
import { getButtonList } from "../../promissionRouter";
import { getShowInformationListPage,addShowInformation,updateShowInformation,deleteShowInformation,setTopShowInformation,uploadImagesUrl } from '../../api/api'
import api from '@/api'
import Qs from 'qs'
import editor from '../../components/kindeditor.vue'
export default {
    components: {Toolbar,editor},
    data() {
        return{
            token: '',
            uploadTempPic: '',
            editorText: '', // 双向同步的变量
            editorTextCopy: '',  // content-change 事件回掉改变的对象
            filters: {
                name: '',
                informationType: '',
                isSetTop: '',
                isEnable:''
            },
            buttonList: [],
            listData: [],
            listLoading: false,
            optionsData: [{
                value: 0,
                label: '法规要求'
                },
                {
                    value: 1,
                    label: '公告信息'
                },
                // {
                //     value:2,
                //     label:'考试项目'
                // }
            ],
            optionsData2: [{
                value: true,
                label: '是'
            },{
                value: false,
                label: '否'
            }],
            optionsData3:[
                {value:true,label:'启用'},
                {value:false,label:'禁用'}
            ],
            optionsData4:[
                {value:true,label:'启用'},
                {value:false,label:'禁用'}
            ],
            addFormVisible: false,
            addFormVisible1: false,
            addLoading: false,
            addOrEdi: true,
            formData: {
                title: '',
                content: '',
                netContent: '',
                isHtml: false,
                remarks:'',
                isEnable:true,
                imageUrl: '',
                informationType: '',
                imageUrlDataStamp1: '',
                SignetWebImgUrl1: '',
            },
            formDataRules: {
                title: [{required: true, message: "请输入标题", trigger: "blur" }],
                netContent: [{required: true, message: "请输入链接", trigger: "blur" }],
                isHtml: [{required: true, message: "请选择是否html", trigger: "change" }],
                isEnable:[{required:true,message:'请选择是否启用',trigger:'change'}],
                informationType: [{required: true, message: "请选择信息类型", trigger: "blur" }],
                imageUrl: [{required: true, message: "请上传图片", trigger: "change" }],
            },
            viewsData: {
                viwesTitle: '',
                viwesContent: '',
            },
            currentRow:null, // 当前行
            // 图片相关
            uploadImagesUrl: '',
            dialogImageUrl: '',
            dialogVisible: false,
            imageUrlData: [],
            BackgroundWebImgUrl: [],
            filesList: [],
            //关于分页的obj
            pages: {
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
        }
    },
    methods: {
        // 富文本编辑相关
        onContentChange (val) {
            this.editorTextCopy = val;
            this.formData.content = val;
        },
        afterChange () {
            
        },
        // 图片相关
        handleRemove(file, fileList) {
            for(let i = 0;i < this.imageUrlData.length;i++){
                if(file.response){
                    if(this.imageUrlData[i].weburl == file.response.Response.weburl){   
                        this.imageUrlData.splice(i,1)
                        // this.BackgroundWebImgUrl.splice(i,1)
                    }
                }else{
                    if(this.imageUrlData[i].url == file.url){
                        this.imageUrlData.splice(i,1)
                        // this.BackgroundWebImgUrl.splice(i,1)
                    }
                }
            }
        },
        handleRemove1(file){

        },
        // 图片上传之前函数
        beforeUpload(file) {
            const isLt2M = file.size / 1024 / 1024 > 2;
            let type = file.name.substring(file.name.lastIndexOf(".")+1);
            if (type == 'jpg' || type == 'png') {} else {
                this.$message.error('上传图片只能是 JPG/PNG 格式!');
                return false;
            }
            if (!isLt2M === false) {
                this.$message.error('上传图片大小不能超过 2MB!');
                return false
            }
        },
        // 图片上传成功时的函数
        handle_success(res) {
            if(res.Success) {
                this.$message.success('图片上传成功')
                this.imageUrlData.push(res.Response)
                this.BackgroundWebImgUrl.push(res.Response.weburl)
                this.formData.imageUrl = 1
            }else{
                this.$message.error(res.Message)
            }
		},
        handle_success1(res) {
            if(res.Success) {
                this.$message.success('图片上传成功')
                this.formData.imageUrlDataStamp1 = res.Response
                this.formData.SignetWebImgUrl1 = res.Response.weburl
            }else{
                this.$message.error(res.Message)
            }
		},
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        // 清空input框，重获数据
        clearContent(){
            this.getListData()
        },
        // 查询
        getShowInformationListPage() {
            this.pages.pageIndex = 1
            this.getListData();
        },
        // 新增
        handleAdd() {
	        this.addOrEdi = true
            this.addFormVisible = true;
            this.imageUrlData = []
            this.BackgroundWebImgUrl = []
            this.filesList = []
            this.formData = {
                title: '',
                content: '',
                netContent: '',
                isHtml: false,
                remarks:'',
                isEnable:true,
                imageUrl: '',
                informationType: '',
                imageUrlDataStamp1: '',
                SignetWebImgUrl1: '',
            }
        },
        // 编辑
        handleEdit(){
            this.addOrEdi = false
            let row = this.currentRow
            if(row == null){
                this.$message({
                    message:'请选择编辑的一行数据',
                    type:'warning'
                })
            }else{
                this.imageUrlData = [],
                this.filesList = []
                this.formData = {
                    title: row.Title,
                    content: row.Content,
                    netContent: row.Content,
                    isHtml: row.IsHtml,
                    remarks:row.Remarks,
                    isEnable:row.IsEnable,
                    imageUrl: '',
                    informationType: row.InformationType,
                    CoverPhotoUrl: row.CoverPhotoUrl,
                    imageUrlDataStamp1: '',
                    SignetWebImgUrl1: row.CoverPhotoWebUrl,
                }
                this.editorText = row.Content
                if(row.Annexs.length > 0){
                    row.Annexs.forEach(item => {
                        let newObj = {}
                        newObj.url = item.AnnexPhotoWebUrl
                        newObj.webupurl = item.AnnexPhotoUrl
                        newObj.ID = item.ID
                        this.filesList.push(newObj)
                        this.imageUrlData.push(newObj)
                    });
                }
                this.addFormVisible = true
            }
        },
        // 删除
        handleDel(){
            let row = this.currentRow
            if(row == null){
                this.$message({
                    message:'请选择要删除的一行数据',
                    type:'warning'
                })
            }else{
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let params = {
                        Id:row.ID
                    }
                    deleteShowInformation(params).then(res => {
                        if(res.data.Success){
                            this.$message({
                                message:res.data.Message,
                                type:'success'
                            })
                            this.getListData()
                        }else{
                            this.$message({
                                message:res.data.Message,
                                type:'error'
                            })
                        }
                    }).catch(() => {})
                }).catch(() => {})
            }
        },
        // 置顶操作
        handleSetTop(ID){
            let params = {
                Id : ID
            }
            setTopShowInformation(params).then(res => {
                if(res.data.Success){
                    this.$message({
                        message:res.data.Message,
                        type:'success'
                    })
                    this.getListData()
                }else{
                    this.$message({
                        message:res.data.Message,
                        type:'success'
                    })
                }
            }).catch(() => {})
        },
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 获取数据
        getListData() {
            let _this = this
            let params = {
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
                title:this.filters.name?this.filters.name:null,
                informationType: 1,
            }
            // if(this.filters.informationType !== ''){
            //     params.informationType = this.filters.informationType
            // }
            if(this.filters.isSetTop !== ''){
                params.isSetTop = this.filters.isSetTop
            }
            if(this.filters.isEnable !== ''){
                params.isEnable = this.filters.isEnable
            }          
            this.listLoading = true;
            getShowInformationListPage(params).then(res => {
                let result = res.data
                if(res.data.Success){
                    this.listLoading = false;
                    this.pages.dataCount = result.Response.DataCount
                    this.pages.pageIndex = result.Response.PageIndex
                    if(result.Response.PageSize > 0){
                        this.pages.pageSize = result.Response.PageSize
                    }
                    this.listData = res.data.Response.Data
                }else{
                    this.listLoading = false
                }
            })
        },
        
        // 点击table某一行
        selectCurrentRow(val) {
            this.currentRow = val
        },
        // 查看
        handleClick(val) {
            this.viewsData = {
                viwesTitle: val.Title,
                viwesContent: val.Content,
                viwesInformationType: val.InformationType,
                viwesIsHtml: val.IsHtml,
                viwesRemarks:val.Remarks,
                viwesIsEnable:val.IsEnable,
                viwesCoverPhotoWebUrl: val.CoverPhotoWebUrl,
                viwesFilePhotoWebUrl: val.Annexs,
            }
            this.addFormVisible1 = true;
        },
        // 提交表单
        addSubmit() {
            let _this = this
            this.$refs.formData.validate(valid => {
                if (valid) {
                    this.addLoading = true;
                    if(this.addOrEdi){
                        let params = {
                            Title: this.formData.title,
                            Content: encodeURIComponent(this.formData.content),
                            IsHtml: this.formData.isHtml,
                            Remarks:this.formData.remarks,
                            IsEnable: this.formData.isEnable,
                            // InformationType: this.formData.informationType,
                            InformationType: 1,
                            Annexs: []
                        }
                        if(this.formData.imageUrlDataStamp1 !== ''){
                            params.CoverPhotoUrl = this.formData.imageUrlDataStamp1.webupurl
                        }
                        if(this.formData.isHtml) {
                            params.Content = encodeURIComponent(this.formData.netContent)
                        }
                        if(this.imageUrlData.length > 0) {
                            this.imageUrlData.forEach(o => {
                                let newObj = {}
                                newObj.AnnexPhotoUrl = o.webupurl
                                params.Annexs.push(newObj)
                            });
                        }else{
                            params.Annexs = []
                        }
                        addShowInformation(params).then(res => {
                            if(res.data.Success){
                                this.$message({
                                    message:res.data.Message,
                                    type:'success'
                                })
                                this.addFormVisible = false
                                this.addLoading = false;
                                this.handleClose()
                                this.getListData()
                            }else{
                                this.$message({
                                    message:res.data.Message,
                                    type:'error'
                                })
                                this.addLoading = false;
                            }
                        })
                    }else{
                        let params = {
                            Title: this.formData.title,
                            Content: encodeURIComponent(this.formData.content) ,
                            IsHtml: this.formData.isHtml,
                            Remarks:this.formData.remarks,
                            IsEnable:this.formData.isEnable,
                            // InformationType: this.formData.informationType,
                            InformationType: 1,
                            Annexs: [],
                            ID:this.currentRow.ID
                        }
                        if(this.formData.imageUrlDataStamp1 !== ''){
                            params.CoverPhotoUrl = this.formData.imageUrlDataStamp1.webupurl
                        }else if(this.formData.imageUrlDataStamp1 == '' && this.formData.SignetWebImgUrl1 !== ''){
                            params.CoverPhotoUrl = this.formData.CoverPhotoUrl
                        }
                        if(this.formData.isHtml) {
                            params.Content = encodeURIComponent(this.formData.netContent)
                        }
                        if(this.imageUrlData.length > 0) {
                            this.imageUrlData.forEach(o => {
                                let newObj = {}
                                newObj.AnnexPhotoUrl = o.webupurl
                                if(o.ID){
                                    newObj.ID = o.ID
                                }
                                params.Annexs.push(newObj)
                            });
                        }else{
                            params.Annexs = []
                        }
                        updateShowInformation(params).then(res => {
                            if(res.data.Success){
                                this.$message({
                                    message:res.data.Message,
                                    type:'success'
                                })
                                this.addFormVisible = false
                                this.addLoading = false;
                                this.handleClose()
                                this.getListData()
                            }else{
                                this.$message({
                                    message:res.data.Message,
                                    type:'error'
                                })
                                this.addLoading = false;
                            }
                        }).catch(() => {})
                    }
                    // setTimeout(function() {
                    //     _this.addLoading = false;
                    // },3000)
                }
            })
        },
        // 分页
        handleCurrentChange(val) {
            // if(this.filters.name != '' || this.filters.informationType != '' || this.filters.isSetTop != '' || this.filters.isEnable != ''){
            //     this.pages.pageIndex = 1
            // }else{
                this.pages.pageIndex = val;
            // }           
            this.getListData();
        },
        handleSizeChange(val){    
            this.pages.pageSize = val;             
            this.getListData();
        },
        // 获取token
        getToken() {
            return localStorage.getItem("Token")
        },
        // 表单清空
        handleClose(){
            this.$refs['formData'].resetFields()
        },
        // 时间转换
        dateFormat(format,date){
            let ret = ''
            date = new Date(date)
            const option = {
                'Y+':date.getFullYear().toString(),  
                'm+':(date.getMonth() + 1).toString(),
                'd+':date.getDate().toString(),
                'H+':date.getHours().toString(),
                'M+':date.getMinutes().toString(),
                'S+':date.getSeconds().toString()
            }
            for(let k in option){
                ret = new RegExp('('+k+')').exec(format)
                if(ret){
                    format = format.replace(
                        ret[1],
                        ret[1].length == 1? option[k] :option[k].padStart(ret[1].length,'0')
                    )
                }
            }
            return format
        }
    },
    computed: {
        headers() {
            return{
                "Authorization": 'Bearer ' + this.getToken() // 直接从本地获取token就行
            }
        }
    },
    watch: {
        'imageUrlData': function() {
            if(this.imageUrlData.length == 0){
                this.formData.imageUrl = ''
            }
        },
    },
    created() {
        this.uploadImagesUrl = api.uploadImagesUrl
        this.uploadTempPic = api.uploadImgByEditor
    },
    mounted() {
        this.getListData();
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
        this.token = this.getToken()
    },
}
</script>

<style lang="stylus" scoped>
    .viwesTitle {
        font-weight bold;
        text-align center;
        font-size 18px;
        margin-bottom 15px;
    }
    .viwesContent {
        text-indent:2em;
        font-size: 16px;
        line-height: 26px;
        text-align: justify;
    }
    .el-textarea>>>.el-textarea__inner {
        min-height 150px!important;
    }
     .pageBar{
         margin:20px!important;
     }
    .avatar {
        width: 100%;
        height: 100%;
        display: block;
    }
    .pictureViews img{
        width 130px!important;
        max-height 150px!important;
        margin-right 10px;
    }
</style>
<style>
    .el-tooltip__popper {
        /* max-width: 800px; */
    }
</style>